.alone {
  background-color: #d1fbbe;
}

sup.ant-scroll-number.ant-badge-count {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex !important;
  font-size: 15px;
}
