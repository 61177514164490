@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .print-only {
    display: block !important;
  }
}

.print-only {
  display: none;
}
