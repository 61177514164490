@tailwind base;
@tailwind components;
@tailwind utilities;

button.ant-btn-primary,
[type="button"].ant-btn-primary,
[type="reset"].ant-btn-primary,
[type="submit"].ant-btn-primary {
  background-color: #1677ff;
}
button.ant-btn-dangerous,
[type="button"].ant-btn-dangerous,
[type="reset"].ant-btn-dangerous,
[type="submit"].ant-btn-dangerous {
  background-color: #ff4d4f;
}
